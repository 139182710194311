<template>
  <div v-if="data.length == 0 && shownodate" class="nodata" >
    <el-empty image="/img/nodata.png" description="抱歉，未找到你想要的图片"/>
  </div>
  <FullLoading v-if="showloging" />
  <div v-else
    ref="containerRef"
    :style="{ height: containerHeight + 'px' }"
    style="position: relative;min-height: 50vh;"
  >
    <div
      v-for="item in data"
      :key="item.coverimg"
      class="item-template absolute duration-300"
      :style="{ width: columnWidth + 'px' }"
      style="position: absolute;"
      @mouseover="showstate=item.id" @mouseout="showstate=-1"
    >
      <div class="image-container" :class="showstate===item.id?'image-container-hover':''">
        <NuxtImg :style="{ height: (columnWidth / item.width) * item.height + 'px' }" :src="item.coverimg" :alt="item.title" :key="item.coverimg" class="waterfall-img" lazy style="width: 100%;border-radius: 5px;" @load="handleImageLoad"/>
        <div></div>
      </div>
      <div v-show="item.id==showstate">
        <NuxtLink :to="'/teminfo/id/' + item.id">
          <div class="template-look templateBus"><i title="查看" class="iconfont icon-chakan2 tubiao"></i></div>
        </NuxtLink>
        <div class="template-like templateBus">
          <i @click="cancelFavorite(item.id)" v-if="item.likestate == 0" title="收藏" class="iconfont icon-hear"></i>
          <i @click="cancelFavorite(item.id)" v-else title="取消收藏" class="iconfont icon-hear-full sc"></i>
        </div>
        <NuxtLink :to="'/designs/id/' + item.id">
          <div class="template-edit">编辑</div>
        </NuxtLink>
        <div class="vip-container" v-if="item.tem_member > 0"><i class="iconfont icon-vip3 tubiao"></i></div>
      </div>
    </div>
  </div>
  <div class="paging" v-if="showpage">
    <!--分页-->
    <el-pagination @change="getPage" :page-size="pagesize" class="paging" defailt background :total="total"  v-model:current-page="page" layout="prev, pager, next "></el-pagination>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick, watch } from 'vue'
import { savelikestate,getPosterList } from '@/src/api/apiconfig/main';
const pagesize = ref(40);//每页显示多少条记录
const total = ref(0);//总条数
// data 是一个数组，存放图片 url 和宽高，格式为：{ url: 'https://xxxx', width: xx, height: xx }
const data = ref([]);
const showstate = ref(-1);//鼠标划入当前元素状态
const column = ref(6);//显示多少列
const rowSpacing = ref(10)//行间距
const columnSpacing = ref(10)//列间距
const containerRef = ref<HTMLElement | null>(null)
const showloging = ref(true);
// 容器总宽度
const containerWidth = ref(0)
// 容器总高度
const containerHeight = ref(0)
// 列宽 = (容器宽度 - 列间距 * (列数 - 1)) / 列数
const columnWidth = ref(0)
const containerLeft = ref(0)

// 计算容器宽度
const useContainerWidth = () => {
  if (process.client) {
    const { paddingLeft, paddingRight } = window.getComputedStyle(containerRef.value!)
    containerLeft.value = parseFloat(paddingLeft)
    containerWidth.value =
      containerRef.value!.clientWidth - parseFloat(paddingLeft) - parseFloat(paddingRight)
  }
}
// 计算列宽
const useColumnWidth = () => {
  columnWidth.value =
    (containerWidth.value - columnSpacing.value * (column.value - 1)) / column.value
}
// 获取最小高度
const getMinHeight = (arr: number[]) => {
  return Math.min(...arr)
}
// 获取最小高度的索引
const getMinHeightIndex = (arr: number[]) => {
  return arr.indexOf(getMinHeight(arr))
}

// 获取所有图片的高度
const itemHeights = ref<number[]>([])
const useItemHeight = () => {
  const allItems = document.querySelectorAll<HTMLElement>('.item-template')
  itemHeights.value = Array.from(allItems).map((item) => item.clientHeight)
}

const columnHeights = ref(Array(column.value).fill(0))

const getItemLeft = () => {
  const column = getMinHeightIndex(columnHeights.value)
  return (columnWidth.value + columnSpacing.value) * column + containerLeft.value
}

const getItemTop = () => {
  return getMinHeight(columnHeights.value)
}

const increaseColumnHeight = (index: number) => {
  const minHeightColumnIndex = getMinHeightIndex(columnHeights.value)
  columnHeights.value[minHeightColumnIndex] += itemHeights.value[index] + rowSpacing.value
}
// 计算每个 item 的位置
const useItemPosition = () => {
  const allItems = document.querySelectorAll<HTMLElement>('.item-template')
  allItems.forEach((item, index) => {
    item.style.left = getItemLeft() + 'px'
    item.style.top = getItemTop() + 'px'
    increaseColumnHeight(index)
  })
  containerHeight.value = Math.max(...columnHeights.value)
}

const gid = ref(useRoute().query.gid ?? 0); // 分类id
const id  = ref(useRoute().query.id ?? 0); // 行业id
const nid = ref(useRoute().query.nid ?? 0); // 场景id
const sid = ref(useRoute().query.sid ?? 0); // 风格id
const cid = ref(useRoute().query.cid ?? 0); // 颜色id
const sort= ref(useRoute().query.s ?? 0); // 排序类型
const bid = ref(useRoute().query.bid ?? 0); // 版式id
const page = ref(useRoute().query.page ?? 1); // 当前页面
const condition = ref(useRoute().query.k ?? '');// 搜索内容
const shownodate = ref(false);//无数据显示
const loadedImages = ref(0); // 已加载的图片数量
const showpage = ref(false);// 等图片都加载完之后再显示分页组件
const handleImageLoad = () => {
  loadedImages.value++;
  // 当加载的图片数量等于总图片数量时，更新分页组件状态
  if (loadedImages.value === data.value.length) {
    showpage.value = true;
  }
}

const getData = async () => {
  data.value = [];
  let response = await getPosterList({
    page: page.value,
    gid:gid.value,
    id:id.value,
    sid:sid.value,
    cid:cid.value,
    sort:sort.value,
    nid:nid.value,
    bid:bid.value,
    condition:condition.value
  })
  data.value = response.data.list
  if(data.value.length == 0 ){
    shownodate.value = true
  }
  pagesize.value = response.data.pagesize
  total.value = response.data.total
  page.value = Number(response.data.page)
  loadedImages.value = 0;
  showpage.value = false;
  showloging.value = false;
}
const route = useRoute();
watch(() => route.query, async(newParam, oldPath) => {
    if(oldPath == undefined){
      return;
    }
    showloging.value = true;
    showpage.value = false;
    gid.value = newParam.gid ?? 0;
    id.value = newParam.id ?? 0;
    sid.value = newParam.sid ?? 0;
    cid.value = newParam.cid ?? 0;
    sort.value = newParam.sort ?? 0;
    nid.value = newParam.nid ?? 0;
    bid.value = newParam.bid ?? 0;
    page.value = newParam.page ?? 1;
    condition.value=newParam.k ?? '';
    containerHeight.value = 0;
    containerLeft.value = 0;
    columnHeights.value = Array(column.value).fill(0)
    shownodate.value = false;
    await getData();
    useContainerWidth()
    useColumnWidth()
    nextTick(() => {
     useItemHeight()
     useItemPosition()
   })

  },
  { immediate: true, deep: true }
);
await getData();

onMounted(async() => {
  columnHeights.value = Array(column.value).fill(0)
  //await getData();
  useContainerWidth()
  useColumnWidth()
  nextTick(() => {
    useItemHeight()
    useItemPosition()
  })

})

watch(column, (value) => {
  // columnHeights.value = Array(value).fill(0)
  // useColumnWidth()
  // nextTick(() => {
  //   useItemHeight()
  //   useItemPosition()
  // })
})
// 分页跳转
const getPage = (async (value)=>{
  navigateTo({ path: 'templateCenter',query:{
      gid:gid.value,
      id:id.value,
      sid:sid.value,
      cid:cid.value,
      nid:nid.value,
      bid:bid.value,
      s:sort.value,
      page:page.value,
      k:condition.value
    }})
})
//收藏/取消收藏接口
const cancelFavorite = async (id)=>{
  const response  = await savelikestate({tid:id,type:1})
  if(response===1){
    data.value.forEach((value,key)=>{
       if(value.id == id){
         value.likestate == 1 ?  value.likestate = 0 : value.likestate = 1;
       }
    })
    ElMessage.success('操作成功')
  }else{
    ElMessage.error('操作失败')
  }
}
</script>
<style>
body{
  overflow-y: scroll;
}
.template-look{
  left: 40px;
  position: absolute;
  top: 5px;
  background: #fff;
  width: 20px;
  height: 20px;
  padding: 5px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .tubiao{
    font-size: 20px !important;
  }
}
.template-like{
  left: 5px;
  position: absolute;
  top: 5px;
  background: #fff;
  width: 20px;
  height: 20px;
  padding: 5px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .tubiao{
    font-size: 16px !important;
  }
}
.template-edit{
  position: absolute;
  right: 10px;
  bottom:15px;
  width: 50px;
  height: 25px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  border-radius: 2px;
  cursor: pointer;
  font-size: 14px;
}
.vip-container{
  position: absolute;
  left: 10px;
  bottom:15px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
  background: rgba(1, 1, 1, 0.4);
  border-radius: 10px;
  .tubiao{
    color:yellow;
  }
}
.opter{
  display:none;
}
.image-container {
  display: inline-block;
  border-radius: 4px;
  overflow: hidden; /* 放大后的图片不超出容器 */
}

.image-container img {
  width: 100%;
  transition: transform 0.3s ease;
  cursor: pointer;
  border-radius: 4px;
}
.image-container-hover img{
  transform: scale(1.1); /* 放大 */
}

.paging{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.nodata{
  display: flex;
  justify-content: center;
}
.sc{
  color:#d71e1e;
}
</style>