<template>
  <div class="input-rq">
    <div class="input-rq-son">
      <el-input
        v-model="input"
        style="width: 530px;"
        size="large"
        class="search-material"
        :prefix-icon="Search"
        @focus="openHistory"
        @blur="closeHistory"
        @input="showclearable"
        @keyup.enter="getSearch(input)"
        placeholder="搜索你想要的模板">
        <template #suffix>
          <i v-show="clearableState" @click="clearableinput" class="clearable iconfont icon-yuanquancuowu"></i>
          <el-button @click="getSearch(input)" class="inputBox-btn">搜索</el-button>
        </template>
      </el-input>
    </div>
    <div v-show="searchShow" class="searchList">
      <div v-show="historyList.length!=0" class="history">
        <div class="history-son-rq"><i class="iconfont icon-lishijilu"></i> <span>历史记录</span></div><div><div @click="getEmpty" class="emptyhistory">清空</div></div>
      </div>
      <div v-if="historyList.length!=0" class="list" >
        <div @click="getSearch(item)" v-for="item in historyList"><span>{{item}}</span></div>
      </div>
      <div class="searchList-title"><span><i style="color: red" class="iconfont icon-huo2"></i> 热门搜索</span></div>
      <div class="searchList-content-rq">
        <div @click="getSearch(item.title)" class="searchList-content" v-for="(item, i) in hotsearchData">
          <div class="searchList-content-num">{{i + 1}}</div><div class="searchList-content-lable">{{item.title}}</div>
        </div>
      </div>
    </div>
    <img src="/img/banner.jpg">
  </div>
  <div class="universalNav">
    <nav class="dis-conter">
      <span class="text-elip template-group-title">类别：</span>
      <ul class="dis-conter" :class="{ 'show-more': showMore }" id="group">
          <li
          v-for="(item, i) in group_data"
          :key="item.id"
          class="text-elip"
          :class="{ selected: gid == item.id }"
        > <NuxtLink :class="{ selecteda: gid == item.id }" :title="item.title" :to="{name:'templateCenter',query:{gid:item.id,id:id,sid:sid,cid:cid,s:sort,nid:0,bid:bid,k:input,page:1}}">
          {{ item.title }}
          </NuxtLink>
        </li>
      </ul>
      <span class="text-elip" @click="showMoreData" v-show="showmoregroupstate">
        {{ showMore ? '收起' : '更多' }}
      </span>
    </nav>
  </div>
  <div class="universalNav">
    <nav class="dis-conter">
      <span class="text-elip template-group-title">场景：</span>
      <ul class="dis-conter" :class="{ 'show-more': showMore }" id="changjing">
        <li
          v-for="(item, i) in changjing_data"
          :key="item.id"
          class="text-elip"
          :class="{ selected: nid == item.id }"
        > <NuxtLink :class="{ selecteda: nid == item.id }" :title="item.title" :to="{name:'templateCenter',query:{gid:gid,id:id,sid:sid,cid:cid,s:sort,nid:item.id,bid:bid,k:input,page:1}}">
          {{ item.title }}
        </NuxtLink>
        </li>
      </ul>
      <span class="text-elip" @click="showMoreData" v-show="showmorechangjingstate">
        {{ showMore ? '收起' : '更多' }}
      </span>
    </nav>
  </div>
  <div class="universalNav">
    <nav class="dis-conter">
      <span class="text-elip template-group-title">行业：</span>
      <ul class="dis-conter" :class="{ 'show-more': showMore }" id="hangye">
        <li
          v-for="(item, i) in hangye_data"
          :key="item.id"
          class="text-elip"
          :class="{ selected: id == item.id }"
        > <NuxtLink :class="{ selecteda: id == item.id }" :to="{name:'templateCenter',query:{gid:gid,id:item.id,sid:sid,cid:cid,s:sort,nid:nid,bid:bid,k:input,page:1}}">
          {{ item.title }}
        </NuxtLink>
        </li>
      </ul>
      <span class="text-elip" @click="showMoreData" v-show="showmorehangyestate">
        {{ showMore ? '收起' : '更多' }}
      </span>
    </nav>
  </div>
  <div class="group-line"></div>
  <div class="more-group">
    <div class="more-group-l" >
      <div @click="handlesort(0)" :style="{fontWeight:sort_value==0 ? 'bold':''}">最新模板</div>
      <div @click="handlesort(1)" :style="{fontWeight:sort_value==1 ? 'bold':''}">热门模板</div>
    </div>
    <div class="more-group-r">
      <div class="common">
        <div class="more-group-lable">颜色：</div>
        <el-dropdown trigger="click" placement="bottom-end" ref="dropdown_color" class="dropdown_color">
              <span class="el-dropdown-link">
                <span class="common-selectted" style="display: flex">
                  <div class="common-selectted-name" v-if="color_value == Number(0)" style="background-image: url(/img/allcolor.png);background-size:100% 100%;width: 20px;height: 20px;border-radius: 20px;"></div>
                  <div class="common-selectted-name" v-else>
                      <div :style="{background:color_value}" style="width: 20px;height: 20px;border-radius: 20px;"></div>
                  </div>
                  <span class="common-selectted-icon"><i class="iconfont icon-xiajiantou2"></i></span>
                </span>
              </span>
          <template #dropdown>
            <el-dropdown-menu class="my-color-select">
              <li class="my-color-select-li" @click="handlecolor(0)">
                <NuxtLink :to="{name:'templateCenter',query:{gid:gid,id:id,sid:sid,cid:0,s:sort,nid:nid,bid:bid,k:input,page:1}}">
                  <div style="background-image: url(/img/allcolor.png);background-size:100% 100%;width: 50px;height: 30px;"></div>
                </NuxtLink>
              </li>
              <li class="my-color-select-li" @click="handlecolor(item.icon)" v-for="(item,index) in color_list">
                <NuxtLink :to="{name:'templateCenter',query:{gid:gid,id:id,sid:sid,cid:item.id,s:sort,nid:nid,bid:bid,k:input,page:1}}">
                  <div :style="{background:item.icon}" style="width: 50px;height: 30px;border: 1px solid rgba(0,0,0,.1);border-radius: 2px;"></div>
                </NuxtLink>
              </li>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="common" style="margin-right: 10px;">
        <div class="more-group-labl">风格：</div>
        <el-dropdown trigger="click" ref="dropdown_style" placement="bottom-end">
            <span class="el-dropdown-link">
              <span class="common-selectted">
                <span class="common-selectted-name">{{style_value}}</span>
                <span class="common-selectted-icon"><i class="iconfont icon-xiajiantou2"></i></span>
              </span>
            </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="handlestyle('全部')">
                <NuxtLink :to="{name:'templateCenter',query:{gid:gid,id:id,sid:0,cid:cid,s:sort,nid:nid,bid:bid,page:1}}">
                  全部
                </NuxtLink>
              </el-dropdown-item>
              <el-dropdown-item v-for="(item,index) in style_list" >
                <NuxtLink :to="{name:'templateCenter',query:{gid:gid,id:id,sid:item.id,cid:cid,s:sort,nid:nid,bid:bid,page:1}}">
                  <div @click="handlestyle(item.title)"  >{{item.title}}</div>
                </NuxtLink>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="common">
        <div class="more-group-labl">版式：</div>
        <el-dropdown trigger="click" ref="dropdown_banshi" placement="bottom-end">
            <span class="el-dropdown-link">
              <span class="common-selectted">
                <span class="common-selectted-name">{{banshi_value}}</span>
                <span class="common-selectted-icon"><i class="iconfont icon-xiajiantou2"></i></span>
              </span>
            </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="handlebanshi('全部')">
                <NuxtLink :to="{name:'templateCenter',query:{gid:gid,id:id,sid:sid.id,cid:cid,s:sort,nid:nid,bid:0,page:1}}">全部</NuxtLink>
              </el-dropdown-item>
              <el-dropdown-item @click="handlebanshi('横板')">
                <NuxtLink :to="{name:'templateCenter',query:{gid:gid,id:id,sid:sid.id,cid:cid,s:sort,nid:nid,bid:1,page:1}}">横板</NuxtLink>
              </el-dropdown-item>
              <el-dropdown-item @click="handlebanshi('竖版')">
                <NuxtLink :to="{name:'templateCenter',query:{gid:gid,id:id,sid:sid.id,cid:cid,s:sort,nid:nid,bid:2,page:1}}">竖版</NuxtLink>
              </el-dropdown-item>
              <el-dropdown-item @click="handlebanshi('方形')">
                <NuxtLink :to="{name:'templateCenter',query:{gid:gid,id:id,sid:sid.id,cid:cid,s:sort,nid:nid,bid:3,page:1}}">方形</NuxtLink>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getPosterGroup,getHotSearch } from '../api/apiconfig/main';
import { useLoginStore } from '@/src/store/modules/login'
import { Search } from '@element-plus/icons-vue'
import { getLocal,setEternalLocal,removeLocal } from "@/src/utils/local";
const loginStore = useLoginStore();
let showMore = ref<boolean>(true);
const showMoreData = () => {
  showMore.value = !showMore.value;
};
// 搜索框使用
const searchShow = ref(false)
const historyList = ref(getLocal('historyList') ?? [])
const hotsearchData = ref([]);
const clearableState = ref(false)
// 选择器使用
const dropdown_color = ref<DropdownInstance>();// 颜色选择列表关闭控制
const dropdown_style = ref<DropdownInstance>();// 风格选择列表关闭控制
const dropdown_banshi = ref<DropdownInstance>();// 版式选择列表关闭控制
const sort_value = ref(0);//排序
const group_data = ref(null); // 类别分类
const changjing_data = ref([]);// 场景分类
const hangye_data = ref([]);//行业分类
const color_list = ref([]);//颜色选择列表
const style_list = ref([]);//风格选择列表
const color_value = ref(0);//当前选中颜色显示内容
const banshi_value = ref('全部');//当前选中版式内容
const style_value = ref('全部');//当前选中风格内容
const error = ref(null); // 用于存储错误信息（如果需要）
const isLoading = ref(false); // 用于表示数据是否正在加载
const group_more = ref(null);//分类更多 显示dom元素
const group_more_li = ref(null);//分类更多 显示dom子li元素
const changjing_more = ref(null);//场景更多 显示dom元素
const changjing_more_li = ref(null);//场景更多 显示dom子li元素
const hangye_more = ref(null);//行业更多 显示dom元素
const hangye_more_li = ref(null);//行业更多 显示dom子li元素

const response = ref();

// 接受url参数
const gid = ref(useRoute().query.gid ?? 0); // 分类id
const id  = ref(useRoute().query.id ?? 0); // 行业id
const sid = ref(useRoute().query.sid ?? 0); // 风格id
const cid = ref(useRoute().query.cid ?? 0); // 颜色id
const sort= ref(useRoute().query.s ?? 0); // 排序类型
const nid = ref(useRoute().query.nid ?? 0); // 场景id
const bid = ref(useRoute().query.bid ?? 0); // 版式id
const input = ref(useRoute().query.k ?? '');// 搜索内容

// 搜索按钮触发
const getSearch = (row) => {
  if(row){
    input.value = row
    historyList.value.unshift(row);
    historyList.value = [...new Set(historyList.value)]
    setEternalLocal('historyList',historyList.value);
    closeHistory();
  }
  navigateTo({ path: 'templateCenter',query:{
      gid:gid.value,
      id:id.value,
      sid:sid.value,
      cid:cid.value,
      nid:nid.value,
      bid:bid.value,
      s:sort.value,
      page:1,
      k:input.value
    }})
}
// 打开历史搜索框
const openHistory = () =>{
  searchShow.value=true
  hotSearch();
  if(input.value !=''){
    clearableState.value = true;
  }
}
// 关闭历史搜索框
const closeHistory = () => {
  clearableState.value = false
  setTimeout(function(){
    searchShow.value = false;
  },150)
}
// 清空搜索内容
const clearableinput = () =>{
  clearableState.value = false;
  input.value = '';

}
// 清空历史搜索
const getEmpty = () => {
  historyList.value.splice(0);
  removeLocal('historyList');
}
const showclearable = () => {
  openHistory();
   if(input.value == ''){
     clearableState.value = false;
   }else{
     clearableState.value = true;
   }
}
// 热门搜索(接口)
const hotSearch = (async ()=>{
  const response = await getHotSearch()
  if(response.data.length!==0){
    hotsearchData.value = response.data
  }else{
    hotsearchData.value = []
  }
})

// 渲染分类列表
const fetchData = async () => {
  try {
    response.value = await getPosterGroup({
      gid:gid.value
    });
    group_data.value = response.value.group; // 将响应数据保存到响应式数据中
    changjing_data.value = response.value.scene; // 将响应数据保存到响应式数据中
    hangye_data.value  = response.value.industry; // 将响应数据保存到响应式数据中
    color_list.value = response.value.color;
    style_list.value = response.value.style;
    error.value = null; // 清除错误信息（如果有的话）
  } catch (err) {
    group_data.value = []; // 清空数据
    changjing_data.value = [];
    hangye_data.value = [];
    error.value = err.message; // 保存错误信息到响应式数据中
  } finally {
    isLoading.value = false; // 数据加载完成，无论成功还是失败
  }
};

const handlecolor = (nowcolor) =>{
  color_value.value = nowcolor
  dropdown_color.value.handleClose()
}
const handlebanshi = (banshi) =>{
  banshi_value.value = banshi;
  dropdown_banshi.value.handleClose()
}
const handlestyle = (style) => {
  style_value.value = style;
  dropdown_style.value.handleClose()
}
// 选择排序
const handlesort = (sorts) =>{
  navigateTo({ path: 'templateCenter',query:{
      gid:gid.value,
      id:id.value,
      sid:sid.value,
      cid:cid.value,
      nid:nid.value,
      bid:bid.value,
      sort:sorts,
      k:input.value
    }})
  sort_value.value = sorts;
}

// 监听路由变化
const route = useRoute();
watch(() => route.query, async(newParam,oldParam) => {
    if(oldParam == undefined){
      return;
    }
    gid.value = newParam.gid ?? 0;
    id.value = newParam.id ?? 0;
    sid.value = newParam.sid ?? 0;
    cid.value = newParam.cid ?? 0;
    sort.value = newParam.sort ?? 0;
    nid.value = newParam.nid ?? 0;
    bid.value = newParam.bid ?? 0;
    sort_value.value = newParam.sort ?? 0;
    input.value=newParam.k ?? '';
    await fetchData();
    // 便于seo使用
    group_data.value.forEach((item, index) => {
      if(gid.value == item.id){
        loginStore.template_g_name = item.title
      }
    })
    changjing_data.value.forEach((item, index) => {
      if(nid.value == item.id){
        loginStore.template_n_name = item.title
      }
    })

    hangye_data.value.forEach((item, index) => {
      if(id.value == item.id){
        loginStore.template_h_name = item.title
      }
    })
},
  { immediate: true, deep: true }
);
// 类别分类超出宽度显示更多按钮
const showmoregroupstate = computed(() => {
  if (group_more_li.value) {
    let ulWidth = group_more.value[0].offsetWidth;
    let liTotalWidth = group_more_li.value.reduce((totalWidth, li) => totalWidth + li.offsetWidth, 0);
    return liTotalWidth > ulWidth;
  }
  return false;
});

const showmorechangjingstate = computed(() => {
  if (changjing_more_li.value) {
    let ulWidth = changjing_more.value[0].offsetWidth;
    let liTotalWidth = changjing_more_li.value.reduce((totalWidth, li) => totalWidth + li.offsetWidth, 0);
    return liTotalWidth > ulWidth;
  }
  return false;
});

const showmorehangyestate = computed(() => {
  if (hangye_more_li.value) {
    let ulWidth = hangye_more.value[0].offsetWidth;
    let liTotalWidth = hangye_more_li.value.reduce((totalWidth, li) => totalWidth + li.offsetWidth, 0);
    return liTotalWidth > ulWidth;
  }
  return false;
});

await fetchData();
onMounted(async () => {
  // 防止颜色赋值问题
  if(cid.value > 0){
    color_list.value.forEach((item,key) => {
      if(item.id == Number(cid.value)){
        color_value.value = item.icon;
      }
    });
  }
  if(sid.value > 0){
    style_list.value.forEach((item,key) => {
      if(item.id == Number(sid.value)){
        style_value.value = item.title;
      }
    });
  }
  if(bid.value > 0){
    if(bid.value == 1){
      banshi_value.value = '横板';
    }else if(bid.value == 2){
      banshi_value.value = '竖板';
    }else if(bid.value == 3){
      banshi_value.value = '方形';
    }else{
      banshi_value.value = '全部';
    }
  }
  group_more.value = Array.from(document.querySelectorAll('.universalNav #group'));
  group_more_li.value = Array.from(document.querySelectorAll('.universalNav #group li'));
  changjing_more.value = Array.from(document.querySelectorAll('.universalNav #changjing'));
  changjing_more_li.value = Array.from(document.querySelectorAll('.universalNav #changjing li'));
  hangye_more.value = Array.from(document.querySelectorAll('.universalNav #hangye'));
  hangye_more_li.value = Array.from(document.querySelectorAll('.universalNav #hangye li'));
});
</script>

<style lang="scss" scoped>
[v-cloak]{
  display:none;
}
.universalNav {
  overflow: hidden;
  display: flex;
  margin-bottom: 8px;
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .dis-conter {
    display: flex;
    align-items: center;
  }
  .text-elip {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }
  .template-group-title{
    color:#8693ab;
  }
}
:deep(.el-dropdown-link:focus-visible){
  outline:unset;
}
:deep(.search-material .el-input__wrapper){
  border-radius: 20px;
}
:deep(.el-input__suffix){
  position: absolute;
  right: 5px;
}
:deep(.search-material .el-button){
  border-radius: 20px;
}
.more-group{
  display: flex;
  justify-content: space-between;
}
.more-group-l{
  display: flex;
  div{
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
  }
}
.more-group-r{
  display: flex;
  .more-group-labl{
    margin-right: 0px;
    font-size: 14px;
  }
}
.common{
  display: flex;
  align-items: center;
}
.common-selectted{
  background: #eee;
  padding: 6px 18px 6px 12px;
  color: #505a71;
  background: #f1f3f9;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  .common-selectted-icon{
    margin-left: 5px;
    i{
      position:absolute;
      top:12px;
      font-size: 8px;
    }
  }
}
.my-color-select{
  width: 118px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
  padding: 5px;
}
.my-color-select-li:nth-child(-n+2){
  width: 50px;
  margin-bottom: 5px;
  cursor: pointer;
}
.my-color-select-li:not(:nth-child(-n+2)) {
  cursor: pointer;
  margin-top: 5px;
}
.my-color-select-li:nth-child(3){
  width: 50px;
  margin-top:0px;
  cursor: pointer;
}
.my-color-select-li:nth-child(4){
  width: 50px;
  margin-top:0px;
  cursor: pointer;
}
.dropdown_color{
  margin-right: 10px;
}
</style>
<style lang='scss' scoped>
.dis-conter {
  width: 100%;
  overflow: hidden;
  ul {
    display: flex;
    height: 38px;
    flex: 1;
    li {
      display: flex;
      padding: 5px 10px;
    }
    .selected {
      background: $webSelectedBg;
      border-radius: 4px;
      color:$webSelectedColor
    }
    a{
      color:#666;
    }
    .selecteda{
      color:$webSelectedColor
    }
  }
  span {
    padding: 8px 0px;
    display: inline-block;
  }
  .show-more {
    height: auto;
  }
}
.group-line{
  width: 100%;
  height: 0.5px;
  background: #ebeef5;
  margin: 20px 0;
}
.input-rq{
  width: 100%;
  height: 85px;
  position: relative;
  margin-bottom: 15px;
  img{
      width: 100%;
      height: 100%;
      object-fit:cover;
      border-radius: 10px;
  }
}
.input-rq-son{
  position: absolute;
  top: calc((100% - 40px) / 2);
  left: calc((100% - 530px) / 2);
}
.clearable{
  cursor: pointer;
  margin-right: 10px;
}
.inputBox-btn{
  border-radius: 20px;
}
input:focus {
  outline: none;
}
.searchList{
  width: 530px;
  background-color: #FFFFFF;
  position: absolute;
  z-index: 999;
  margin-top: 8px;
  border-radius: 5px;
  padding-top: 5px;
  top: calc((100% + 35px) / 2);
  left: calc((100% - 530px) / 2);
  padding-bottom: 10px;
  box-shadow: 0px 0px 10px 0px rgba(33, 32, 32, 0.1);

  .searchList-content-rq{
    display: flex;
    flex-wrap: wrap;
  }
  .searchList-content{
    cursor: pointer;
    width: 50%;
    height: 30px;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    display: flex;
    align-items: center;
    .searchList-content-num{
      margin-left: 13px;
      height: 16px;
      width: 16px;
      background: #c5c8ce;
      border-radius: 3px;
      text-align: center;
      line-height: 16px;
      font-size: 12px;
      color: #fff;
    }
    .searchList-content-lable{
      width: 100%;
      margin-left: 5px;
    }
  }
  .searchList-content:first-child .searchList-content-num{
    background: linear-gradient(1turn,#f91821,#ff6115);
  }
  .searchList-content:nth-child(2) .searchList-content-num{
    background: linear-gradient(180deg,#fdca00 -25%,#ff4501);
  }
  .searchList-content:nth-child(3) .searchList-content-num{
    background: linear-gradient(135deg,#ffe324 1.73%,#ff9c33);
  }
  .searchList-title{
    width: 100%;
    height: 30px;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    display: flex;
    align-items: center;
    span{
      margin-left: 10px;
      color:#888;
      font-weight: bold;
    }

  }
  .history{
    display: flex;
    justify-content:space-between;
    margin-left: 10px;
    margin-right: 10px;
    .history-son-rq{
      display: flex;
      align-items: center;
      i{
        margin-right: 4px;
      }
    }
    .emptyhistory{
      cursor: pointer;
    }
    div{
      font-size: 14px;
      font-weight: bold;
      color:#888;
    }
  }
  .list{
    width: 100%;
    display: flex;
    flex-wrap:wrap;
    margin-top: 5px;
    margin-bottom: 10px;
    div:hover{
      background-color: #F0EFF4;
    }
    div{
      cursor: pointer;
      display: flex;
      background-color:  #F8F8F8;
      border-radius: 5px;
      margin-left: 10px;
      margin-top: 5px;
      padding: 5px 5px;
    }
    span{
      font-size: 12px;

    }
  }
}
</style>
